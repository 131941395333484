import React from "react";
import { getIn } from "formik";
import { TextField } from "@mui/material";
import TimeRange from "react-time-range";

export const WorkingScheduleField: React.FC<any> = ({
  field,
  form,
  ...props
}) => {
  const [startTime, setStartTime] = React.useState(
    field.value != "" ? field.value.split(" - ")[0] : "2001-01-01T10:00"
  );
  const [endTime, setEndTime] = React.useState(
    field.value != "" ? field.value.split(" - ")[1] : "2001-01-01T18:00"
  );
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);
  const formatTimeRange = (start, end) => `${start} - ${end}`;
  const handleTimeRangeChange = (selectedTime) => {
    setStartTime(selectedTime.startTime);
    setEndTime(selectedTime.endTime);

    const formattedTimeRange = formatTimeRange(
      selectedTime.startTime,
      selectedTime.endTime
    );
    form.setFieldValue(field.name, formattedTimeRange);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "1rem",
        color: props.disabled ? "rgba(0, 0, 0, 0.38)" : "rgba(0, 0, 0, 0.6)",
      }}
    >
      Working schedule
      <div
        style={
          props.disabled
            ? {
                pointerEvents: "none",
                color: "rgba(0, 0, 0, 0.38)",
              }
            : { color: "black" }
        }
      >
        <TimeRange
          startLabel={""}
          endLabel={"-"}
          startMoment={startTime}
          endMoment={endTime}
          use24Hours={true}
          onChange={handleTimeRangeChange}
        />
      </div>
    </div>
  );
};
