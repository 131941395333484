import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Popover from "@mui/material/Popover";
import { alpha } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function AccountPopover({ info }) {
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };

  const logOut = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const goToHome = () => {
    navigate("/");
  };

  const goToProfile = () => {
    navigate("/profile");
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          marginRight: 0.5,
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          className="avatar-popover"
          src={info.photo}
          alt={info.name}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {info.name.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1,
            ml: 0.75,
            width: 200,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2 }}>
          <Typography
            variant="subtitle2"
            noWrap
            style={{ fontFamily: "SeagoeUIBold" }}
          >
            {info.name}
          </Typography>
          <Typography
            variant="body2"
            style={{ fontFamily: "SeagoeUI" }}
            sx={{ color: "text.secondary" }}
            noWrap
          >
            {info.gsd_email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem
          key="home"
          onClick={goToHome}
          style={{ fontFamily: "SeagoeUI" }}
        >
          Home
        </MenuItem>
        <MenuItem
          key="profile"
          onClick={goToProfile}
          style={{ fontFamily: "SeagoeUI" }}
        >
          Profile
        </MenuItem>

        <Divider sx={{ borderStyle: "dashed", m: 0 }} />

        <MenuItem
          disableRipple
          disableTouchRipple
          onClick={logOut}
          sx={{ typography: "body2", color: "error.main", py: 1.5 }}
          style={{ fontFamily: "SeagoeUI" }}
        >
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}

export default AccountPopover;
