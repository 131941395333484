import * as React from "react";
import { Formik, Field, Form } from "formik";
import { TextFormField } from "../../components/textform/TextFormField.tsx";
import * as yup from "yup";
import YupPassword from "yup-password";
import { FindUser, UpdateUsers, UpdateUsersPass } from "../../services/user.js";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import Checkbox from "@mui/material/Checkbox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FormControlLabel from "@mui/material/FormControlLabel";
import { WorkingScheduleField } from "../../components/timeform/WorkingScheduleField.tsx";
import moment from "moment";
YupPassword(yup);

export const form = [
  {
    name: "name",
    label: "Full Name",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "personal_email",
    label: "Personal Email",
    disabled: true,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "gsd_email",
    label: "GSD Email",
    disabled: true,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "position",
    label: "Position",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "role",
    label: "Role",
    disabled: false,
    component: TextFormField,
    select: true,
    options: [
      { value: "ROLE_ADMIN", label: "Admin" },
      { value: "ROLE_USER", label: "User" },
    ],
    style: {
      width: "49%",
    },
  },
  {
    name: "phone",
    label: "Phone",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "client",
    label: "Client",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "manager",
    label: "Manager",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "address",
    label: "Address",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "marca",
    label: "Marca",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "hire_date",
    label: "Hire Date",
    type: "date",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "monitors",
    label: "Monitors",
    disabled: false,
    component: TextFormField,
    select: true,
    options: [
      { value: 0, label: "0" },
      { value: 1, label: "1" },
      { value: 2, label: "2" },
    ],
    style: {
      width: "49%",
    },
  },
  {
    name: "working_schedule",
    label: "Working Schedule",
    disabled: false,
    component: WorkingScheduleField,
    style: {
      width: "49%",
    },
  },
];

export const validation = yup.object().shape({
  name: yup.string().required("Full Name is required"),
  personal_email: yup
    .string()
    .required("Email is required")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email"
    ),
  gsd_email: yup
    .string()
    .required("Email is required")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email"
    ),
  // photo: yup.string().required("Photo is required"),
  position: yup.string().required("Position is required"),
  role: yup.string().required("Role is required"),
  phone: yup.string().required("Phone is required"),
  // working_schedule: yup.string().required("Working Schedule is required"),
  client: yup.string().required("Client is required"),
  manager: yup.string().required("Manager is required"),
  address: yup.string().required("Address is required"),
  marca: yup.string().required("Marca is required"),
  hire_date: yup.string().required("Hire Date is required"),
  monitors: yup.string().required("Monitors is required"),
});

export const formPass = [
  {
    name: "password",
    label: "Password",
    disabled: false,
    component: TextFormField,
    style: {
      width: "70%",
    },
  },
  {
    name: "confirm_password",
    label: "Confirm Password",
    disabled: false,
    component: TextFormField,
    style: {
      width: "70%",
    },
  },
];

export const validationPass = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(
      8,
      "password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special"
    )
    .minLowercase(1, "password must contain at least 1 lower case letter")
    .minUppercase(1, "password must contain at least 1 upper case letter")
    .minNumbers(1, "password must contain at least 1 number")
    .minSymbols(1, "password must contain at least 1 special character"),
  confirm_password: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export default function UpdateUser() {
  const [error, setError] = useState(false);
  const [errorDescription, setErrorDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [user, setUser] = useState(null);
  const [togglePassForm, setTogglePassForm] = useState(false);
  const [file, setFile] = useState(null);
  const [checkboxes, setCheckboxes] = useState([]);
  const navigate = useNavigate();

  const handleFileRead = async (event) => {
    return await convertBase64(event);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (file) fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleError = (description) => {
    setError(true);
    setErrorDescription(description);
    alert(description);
  };

  const handleCheckbox = (event) => {
    setCheckboxes({
      ...checkboxes,
      [event.target.name]: event.target.checked,
    });
  };

  const submitForm = async (values) => {
    var base64 = null;
    if (file != null) {
      base64 = await handleFileRead(file);
    } else {
      base64 = user.photo;
    }
    const date = new Date(values.hire_date);

    const data = {
      name: values.name,
      personal_email: values.personal_email,
      gsd_email: values.gsd_email,
      position: values.position,
      role: values.role,
      phone: values.phone,
      working_schedule: values.working_schedule,
      client: values.client,
      manager: values.manager,
      address: values.address,
      marca: values.marca,
      hire_date: date.toISOString(),
      monitors: values.monitors,
      photo: base64,
      laptop_gsd: checkboxes.laptop_gsd,
      laptop: checkboxes.laptop,
      monitor_1: checkboxes.monitor_1,
      monitor_2: checkboxes.monitor_2,
      keyboard_mouse: checkboxes.keyboard_mouse,
      headset: checkboxes.headset,
      docking: checkboxes.docking,
    };

    var jsonData = JSON.stringify(data);
    try {
      setLoading(true);
      const content = await UpdateUsers(jsonData, id);

      if (content.error) {
        handleError(content.error);
      } else if (content.status === "OK") {
        navigate("/user");
      }
    } catch {
      handleError("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleButtonClick = (event) => {
    document.querySelector("#file-input").click();
  };

  const submitFormPass = async (values) => {
    const data = {
      password: values.password,
    };
    var jsonData = JSON.stringify(data);
    try {
      setLoading(true);
      const content = await UpdateUsersPass(jsonData, id);

      if (content.error) {
        handleError(content.error);
      } else if (content.status === "OK") {
        alert("Password updated");
        setTogglePassForm(false);
      }
    } catch {
      handleError("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = window.location.href;
        const newId = url.split("/").pop();
        setId(newId);
        const content = await FindUser(newId);
        setUser(content.data);
        setCheckboxes({
          laptop_gsd: content.data.laptop_gsd,
          laptop: content.data.laptop,
          monitor_1: content.data.monitor_1,
          monitor_2: content.data.monitor_2,
          keyboard_mouse: content.data.keyboard_mouse,
          headset: content.data.headset,
          docking: content.data.docking,
        });
        setLoading(false);
      } catch (error) {
        handleError(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {user != null && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="form-wrapper"
        >
          <div
            className="upload-container"
            style={
              !togglePassForm
                ? { maxHeight: "500px", transition: "max-height 0.5s" }
                : {
                    maxHeight: "750px",
                    transition: "max-height 0.5s",
                  }
            }
          >
            <span className="job-role-text">{user.position}</span>
            <div className="input-circle" onClick={handleButtonClick}>
              <input
                type="file"
                id="file-input"
                name="photo"
                className="input-field"
                accept="image/*"
                onChange={(event) => {
                  setFile(event.target.files[0]);
                }}
              />

              <div className="inner-circle">
                <img
                  src={file ? URL.createObjectURL(file) : user.photo}
                  alt="preview"
                  className="inner-circle-img"
                ></img>
                <div className="hover-overlay">
                  <AddAPhotoIcon
                    sx={{
                      width: "32px",
                      height: "32px",
                    }}
                  />
                  <span className="upload-text">Update photo</span>
                </div>
              </div>
            </div>
            <span className="upload-text-2">
              Allowed *.jpeg, *.jpg, *.png, *.gif
              <br />
              max size of 3.1 MB
            </span>
            <div className="checkbox-div">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                    onChange={handleCheckbox}
                    checked={checkboxes.laptop_gsd}
                  />
                }
                label="Laptop GSD"
                name="laptop_gsd"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                    onChange={handleCheckbox}
                    checked={checkboxes.laptop}
                  />
                }
                label="Laptop"
                name="laptop"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                    onChange={handleCheckbox}
                    checked={checkboxes.monitor_1}
                  />
                }
                label="Monitor 1"
                name="monitor_1"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                    onChange={handleCheckbox}
                    checked={checkboxes.monitor_2}
                  />
                }
                label="Monitor 2"
                name="monitor_2"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                    onChange={handleCheckbox}
                    checked={checkboxes.keyboard_mouse}
                  />
                }
                label="Keyboard Mouse"
                name="keyboard_mouse"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                    onChange={handleCheckbox}
                    checked={checkboxes.headset}
                  />
                }
                label="Headset"
                name="headset"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                    onChange={handleCheckbox}
                    checked={checkboxes.docking}
                  />
                }
                label="Docking"
                name="docking"
              />
            </div>
            <button
              className="toggle-pass-btn"
              onClick={() => setTogglePassForm(!togglePassForm)}
            >
              Update Password
              {!togglePassForm ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )}
            </button>

            <div
              style={{
                height: "auto",
                maxHeight: togglePassForm ? "300px" : 0,
                overflow: "hidden",
                transition: "max-height 0.5s",
              }}
            >
              <Formik
                validationSchema={validationPass}
                initialValues={{
                  password: "",
                  confirm_password: "",
                }}
                onSubmit={(values) => {
                  submitFormPass(values);
                }}
              >
                {() => (
                  <Form
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div className="form-position">
                      {formPass.map((snapshot, key) => {
                        return <Field {...snapshot} key={key} />;
                      })}
                    </div>
                    <button
                      className="submit-form"
                      type="submit"
                      style={{ marginBottom: "0px" }}
                    >
                      Save Changes
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>

          <div className="form-container">
            <Formik
              validationSchema={validation}
              initialValues={{
                name: user.name,
                personal_email: user.personal_email,
                gsd_email: user.gsd_email,
                photo: "",
                position: user.position,
                role: user.role,
                phone: user.phone,
                working_schedule: user.working_schedule,
                client: user.client,
                manager: user.manager,
                address: user.address,
                marca: user.marca,
                hire_date: moment(user.hire_date).format("YYYY-MM-DD"),
                monitors: user.monitors,
              }}
              onSubmit={(values) => {
                submitForm(values);
              }}
            >
              {() => (
                <Form
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div className="form-position">
                    {form.map((snapshot, key) => {
                      return <Field {...snapshot} key={key} />;
                    })}
                  </div>
                  <button className="submit-form" type="submit">
                    Save Changes
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
}
