import React, { useLayoutEffect, useState } from "react";
import { User, Home, File, LogOut } from "react-feather";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

export function SidebarNav({ info, router: { location } }) {
  const navigate = useNavigate();

  const conditionRole = info.role === "ROLE_ADMIN";
  let pathname = location.pathname;
  const [width, setWidth] = useState(window.innerWidth);

  const logOut = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  useLayoutEffect(() => {
    function updateWidth() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateWidth);
    updateWidth();

    if (width < 992) {
      document.getElementById("sidebar-logout").style.display = "none";
      document.getElementById("minimized-logout").style.display = "flex";
      document.getElementById("sidebar-title").style.visibility = "hidden";
      const arr = document.getElementsByClassName("hide-sidebar");
      for (let i = 0; i < arr.length; i++) {
        arr[i].style.display = "none";
      }
    } else {
      document.getElementById("sidebar-logout").style.display = "flex";
      document.getElementById("minimized-logout").style.display = "none";
      document.getElementById("sidebar-title").style.visibility = "visible";
      const arr = document.getElementsByClassName("hide-sidebar");
      for (let i = 0; i < arr.length; i++) {
        arr[i].style.display = "inline";
      }
    }

    return () => window.removeEventListener("resize", updateWidth);
  });

  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar-inner">
        <div id="sidebar-menu" className="sidebar-menu">
          <div
            style={{ marginBottom: 20, color: "rgba(35, 43, 53)" }}
            id="sidebar-title"
          >
            Overview
          </div>
          <ul>
            <li className={pathname === "/" ? "active" : ""}>
              <Link to="/" style={{ textDecorationLine: "none" }}>
                <Home />{" "}
                <span
                  style={{ fontSize: "SeagoeUI", marginLeft: 20 }}
                  className="hide-sidebar"
                >
                  Dashboard
                </span>
              </Link>
            </li>

            {conditionRole && (
              <li className={pathname.match("/user") ? "active" : ""}>
                <Link to="/user" style={{ textDecorationLine: "none" }}>
                  <User />{" "}
                  <span
                    style={{ fontSize: "SeagoeUI", marginLeft: 20 }}
                    className="hide-sidebar"
                  >
                    User
                  </span>
                </Link>
              </li>
            )}

            {conditionRole && (
              <li className={pathname.match("/documents") ? "active" : ""}>
                <Link to="/documents" style={{ textDecorationLine: "none" }}>
                  <File />{" "}
                  <span
                    style={{ fontSize: "SeagoeUI", marginLeft: 20 }}
                    className="hide-sidebar"
                  >
                    Documents
                  </span>
                </Link>
              </li>
            )}
          </ul>
        </div>
        <div className="overview-sidebar" id="sidebar-logout">
          <div className="avatar-sidebar">
            <div className="avatar-top-right">
              <p className="role-sidebar">
                {info.role === "ROLE_ADMIN" ? "Admin" : "Employee"}
              </p>
            </div>
            <img
              src={info.photo}
              alt={info.name}
              width={44}
              height={44}
              style={{ borderRadius: "50%" }}
            />
          </div>
          <p className="name-sidebar">{info.name}</p>
          <p className="email-sidebar">{info.gsd_email}</p>
          <div className="button-sidebar" onClick={logOut}>
            <p
              style={{
                color: "#fff",
                fontFamily: "SeagoeUIBold",
                fontSize: "0.8rem",
              }}
            >
              Log out
            </p>
          </div>
        </div>
        <div id="minimized-logout">
          <LogOut onClick={logOut} />{" "}
        </div>
      </div>
    </div>
  );
}

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

export default withRouter(SidebarNav);
