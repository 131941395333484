import Popover from "@mui/material/Popover";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { useState, useEffect, useLayoutEffect } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

function MenuPopoover({ info }) {
  const [open, setOpen] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [value, setValue] = useState(new Date());
  const [width, setWidth] = useState(window.innerWidth);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };

  const dateTime =
    value.toLocaleDateString() + " " + value.toLocaleTimeString();

  useEffect(() => {
    const interval = setInterval(() => setValue(new Date()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useLayoutEffect(() => {
    function updateWidth() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateWidth);
    updateWidth();

    if (width >= 650) {
      handleClose();
    }

    return () => window.removeEventListener("resize", updateWidth);
  });

  const handleFullscreen = () => {
    const element = document.documentElement;

    if (!isFullscreen) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }

    setIsFullscreen((prev) => !prev);
  };

  return (
    <>
      <MoreHorizIcon
        onClick={handleOpen}
        sx={{
          marginRight: 0.75,
          width: 40,
          height: 40,
        }}
      ></MoreHorizIcon>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1,
            ml: 0.75,
            width: 200,
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
          },
        }}
      >
        <button id="fullscreen-btn" type="button" onClick={handleFullscreen}>
          <FullscreenIcon id="fullscreen-icon" />
          <div style={{ display: "flex", alignItems: "center" }}>
            {" "}
            <span
              style={{
                textDecoration: "underline",
                fontWeight: "bold",
                fontSize: "0.8rem",
                marginBottom: "2px",
                fontFamily: "SeagoeUIBold",
                letterSpacing: "0.4px",
              }}
            >
              {isFullscreen ? "Minimize" : "Maximize"}
            </span>
          </div>
        </button>

        <ul className="datetime">{dateTime}</ul>
      </Popover>
    </>
  );
}

export default MenuPopoover;
