export default async function fetcher(path, method, body = null) {
  let headers = {};
  let token = localStorage.getItem("token");

  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
  }
  const response = await fetch(process.env.REACT_APP_REQUEST_URL + path, {
    method,
    headers,
    body,
  });

  return response;
}

export async function fetcherDownloadDoc(path) {
  let headers = {};
  let token = localStorage.getItem("token");

  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
  }

  const response = await fetch(process.env.REACT_APP_REQUEST_URL + path, {
    method: "GET",
    headers,
  });

  const blob = await response.blob();
  const href = window.URL.createObjectURL(blob);

  return href;
}
