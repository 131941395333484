import * as React from "react";
import { Formik, Field, Form } from "formik";
import { TextFormField } from "../../components/textform/TextFormField.tsx";
import * as yup from "yup";
import { AddUsers } from "../../services/user.js";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import YupPassword from "yup-password";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { WorkingScheduleField } from "../../components/timeform/WorkingScheduleField.tsx";

// import AlertModal from "../../components/modals/alert.js";
YupPassword(yup);

export const form = [
  {
    name: "name",
    label: "Full Name",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "personal_email",
    label: "Personal Email",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "gsd_email",
    label: "GSD Email",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "password",
    label: "Password",
    disabled: false,
    component: TextFormField,
    type: "password",
    style: {
      width: "49%",
    },
  },
  {
    name: "confirm_password",
    label: "Confirm Password",
    disabled: false,
    component: TextFormField,
    type: "password",
    style: {
      width: "49%",
    },
  },
  {
    name: "position",
    label: "Position",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "role",
    label: "Role",
    disabled: false,
    component: TextFormField,
    select: true,
    options: [
      { value: "ROLE_ADMIN", label: "Admin" },
      { value: "ROLE_USER", label: "User" },
    ],
    style: {
      width: "49%",
    },
  },
  {
    name: "phone",
    label: "Phone",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "client",
    label: "Client",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "manager",
    label: "Manager",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "address",
    label: "Address",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "marca",
    label: "Marca",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "hire_date",
    label: "Hire Date",
    disabled: false,
    type: "date",
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "monitors",
    label: "Monitors",
    disabled: false,
    component: TextFormField,
    select: true,
    options: [
      { value: 0, label: "0" },
      { value: 1, label: "1" },
      { value: 2, label: "2" },
    ],
    style: {
      width: "49%",
    },
  },
  {
    name: "working_schedule",
    label: "Working Schedule",
    disabled: false,
    component: WorkingScheduleField,
    style: {
      width: "49%",
    },
  },
];

export const validation = yup.object().shape({
  name: yup.string().required("Full Name is required"),
  personal_email: yup
    .string()
    .required("Email is required")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email"
    ),
  gsd_email: yup
    .string()
    .required("Email is required")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email"
    ),
  password: yup
    .string()
    .required("Password is required")
    .min(
      8,
      "password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special"
    )
    .minLowercase(1, "password must contain at least 1 lower case letter")
    .minUppercase(1, "password must contain at least 1 upper case letter")
    .minNumbers(1, "password must contain at least 1 number")
    .minSymbols(1, "password must contain at least 1 special character"),
  confirm_password: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  // photo: yup.string().required("Photo is required"),
  position: yup.string().required("Position is required"),
  role: yup.string().required("Role is required"),
  phone: yup.string().required("Phone is required"),
  // working_schedule: yup.string().required("Working Schedule is required"),
  client: yup.string().required("Client is required"),
  manager: yup.string().required("Manager is required"),
  address: yup.string().required("Address is required"),
  marca: yup.string().required("Marca is required"),
  hire_date: yup.string().required("Hire Date is required"),
  monitors: yup.string().required("Monitors is required"),
});

export default function CreateUser() {
  const [error, setError] = useState(false);
  const [errorDescription, setErrorDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [checkboxes, setCheckboxes] = useState([]);

  const navigate = useNavigate();

  const handleFileRead = async (event) => {
    return await convertBase64(event);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (file) fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleError = (description) => {
    setError(true);
    setErrorDescription(description);
    alert(description);
  };

  const handleCheckbox = (event) => {
    setCheckboxes({
      ...checkboxes,
      [event.target.name]: event.target.checked,
    });
  };

  const submitForm = async (values) => {
    const base64 = await handleFileRead(file);
    const date = new Date(values.hire_date);

    const data = {
      name: values.name,
      personal_email: values.personal_email,
      gsd_email: values.gsd_email,
      password: values.password,
      position: values.position,
      role: values.role,
      phone: values.phone,
      working_schedule: values.working_schedule,
      client: values.client,
      manager: values.manager,
      address: values.address,
      marca: values.marca,
      hire_date: date.toISOString(),
      monitors: values.monitors,
      photo: base64,
      laptop_gsd: checkboxes.laptop_gsd,
      laptop: checkboxes.laptop,
      monitor_1: checkboxes.monitor_1,
      monitor_2: checkboxes.monitor_2,
      keyboard_mouse: checkboxes.keyboard_mouse,
      headset: checkboxes.headset,
      docking: checkboxes.docking,
    };

    var jsonData = JSON.stringify(data);
    try {
      setLoading(true);
      const content = await AddUsers(jsonData);

      if (content.error) {
        handleError(content.error);
      } else if (content.status === "OK") {
        navigate("/user");
      }
    } catch {
      handleError("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleButtonClick = () => {
    document.querySelector("#file-input").click();
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="form-wrapper"
      >
        <div className="upload-container">
          <div className="input-circle" onClick={handleButtonClick}>
            <input
              type="file"
              id="file-input"
              name="photo"
              className="input-field"
              accept="image/*"
              onChange={(event) => {
                setFile(event.target.files[0]);
              }}
            />
            <div className="inner-circle">
              {file ? (
                <div className="inner-circle-img-container">
                  <img
                    src={URL.createObjectURL(file)}
                    alt="preview"
                    className="inner-circle-img"
                  />
                  <div className="add-photo-overlay">
                    <AddAPhotoIcon />
                    <span className="upload-text">Update photo</span>
                  </div>
                </div>
              ) : (
                <>
                  <AddAPhotoIcon
                    sx={{
                      width: "32px",
                      height: "32px",
                    }}
                  />
                  <span className="upload-text">Upload photo</span>
                </>
              )}
            </div>
          </div>
          <span className="upload-text-2">
            Allowed *.jpeg, *.jpg, *.png, *.gif
            <br />
            max size of 3.1 MB
          </span>
          <div className="checkbox-div">
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                  onChange={handleCheckbox}
                />
              }
              label="Laptop GSD"
              name="laptop_gsd"
            />
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                  onChange={handleCheckbox}
                />
              }
              label="Laptop"
              name="laptop"
            />
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                  onChange={handleCheckbox}
                />
              }
              label="Monitor 1"
              name="monitor_1"
            />
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                  onChange={handleCheckbox}
                />
              }
              label="Monitor 2"
              name="monitor_2"
            />
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                  onChange={handleCheckbox}
                />
              }
              label="Keyboard Mouse"
              name="keyboard_mouse"
            />
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                  onChange={handleCheckbox}
                />
              }
              label="Headset"
              name="headset"
            />
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                  onChange={handleCheckbox}
                />
              }
              label="Docking"
              name="docking"
            />
          </div>
        </div>

        <div className="form-container">
          <Formik
            validationSchema={validation}
            initialValues={{
              name: "",
              personal_email: "",
              gsd_email: "",
              password: "",
              confirm_password: "",
              photo: "",
              position: "",
              role: "",
              phone: "",
              working_schedule: "",
              client: "",
              manager: "",
              address: "",
              marca: "",
              hire_date: "0000-00-00",
              monitors: 0,
            }}
            onSubmit={(values) => {
              if (!file) {
                handleError("Photo is required");
              }
              submitForm(values);
            }}
          >
            {() => (
              <Form
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div className="form-position">
                  {form.map((snapshot, key) => {
                    return <Field {...snapshot} key={key} />;
                  })}
                </div>
                <button className="submit-form" type="submit">
                  Create User
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
