import React, { useEffect, useState, useLayoutEffect } from "react";
import "../../assets/App.css";
import logoicon from "../../assets/logoicon-gsd.png";
import AccountPopover from "./popover";
import ukflag from "../../assets/flag-united-kingdom.svg";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import MenuPopoover from "./menuPopover";
import Popover from "@mui/material/Popover";

export default function Header({ info }) {
  const [open, setOpen] = useState(null);
  const [value, setValue] = useState(new Date());
  const [languageDropdown, setLanguageDropdown] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const dateTime =
    value.toLocaleDateString() + " " + value.toLocaleTimeString();

  useEffect(() => {
    const interval = setInterval(() => setValue(new Date()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useLayoutEffect(() => {
    function updateWidth() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateWidth);
    updateWidth();

    if (width < 650) {
      document.getElementById("minimized-header").style.display = "flex";
      const arr = document.getElementsByClassName("minimized-header");
      for (let i = 0; i < arr.length; i++) {
        arr[i].style.display = "none";
      }
    } else {
      document.getElementById("minimized-header").style.display = "none";
      const arr = document.getElementsByClassName("minimized-header");
      for (let i = 0; i < arr.length; i++) {
        arr[i].style.display = "flex";
      }
    }

    return () => window.removeEventListener("resize", updateWidth);
  });

  const toggleLanguageDropdown = () => {
    setLanguageDropdown((prev) => !prev);
    handleClose();
  };

  const handleFullscreen = () => {
    const element = document.documentElement;

    if (!isFullscreen) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }

    setIsFullscreen((prev) => !prev);
  };

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="header"
      >
        <div className="header-left">
          <div style={{ width: 90, height: 40, display: "grid" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: 15,
              }}
            >
              <a href="/">
                <img src={logoicon} alt="Logo" height="40" />
              </a>
            </div>
          </div>
        </div>

        <a href="#0" className="mobile_btn" id="mobile_btn">
          <i className="fa fa-bars" />
        </a>
        <div className="header-right">
          <div className="header-right-left-side">
            <button
              id="fullscreen-btn"
              type="button"
              onClick={handleFullscreen}
              className="minimized-header"
            >
              <FullscreenIcon id="fullscreen-icon" />
              <div style={{ display: "flex", alignItems: "center" }}>
                {" "}
                <span
                  style={{
                    textDecoration: "underline",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    marginBottom: "2px",
                    fontFamily: "SeagoeUIBold",
                    letterSpacing: "0.4px",
                  }}
                >
                  {isFullscreen ? "Minimize" : "Maximize"}
                </span>
              </div>
            </button>
            <button id="dropdown-flag" type="button" onClick={handleOpen}>
              <img
                src={ukflag}
                alt="uk-flag"
                className="img-uk-flag"
                width={30}
                height={30}
              />
            </button>
            <Popover
              open={!!open}
              anchorEl={open}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              PaperProps={{
                sx: {
                  p: 0,
                  mt: 1,
                  width: 130,
                  borderRadius: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  justifyContent: "center",
                },
              }}
            >
              <div className="flags-menu">
                <ul
                  className={`lang-menu ${languageDropdown ? "" : "hidden"}`}
                  onClick={toggleLanguageDropdown}
                >
                  <li>
                    <img src={ukflag} alt="uk-flag" className="img-uk-flag" />
                    English
                  </li>
                </ul>
              </div>
            </Popover>
          </div>

          <ul className="datetime minimized-header">{dateTime}</ul>
          <div id="minimized-header">
            <MenuPopoover />
          </div>
          <span className="job-role-text-header">{info.position}</span>
          <AccountPopover info={info} />
        </div>
      </div>
    </>
  );
}
