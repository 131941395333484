import fetcher, { fetcherDownloadDoc } from "../helpers/fetcher";

export async function FindDocuments() {
  let path = `/documents`;

  let request = await fetcher(path, "GET");

  let content = request.json();

  return content;
}

export async function PreviewDocument(doc) {
  let path = `/documents/${doc}`;

  let request = await fetcherDownloadDoc(path, "GET");

  return request;
}

export async function GenerateDocument(doc) {
  let path = `/document/${doc}`;

  let request = await fetcherDownloadDoc(path, "GET");

  return request;
}
