import { getIn } from "formik";
import React from "react";
import { TextField, MenuItem } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

export const TextFormField: React.FC<any> = ({ field, form, ...props }) => {
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);

  const media = useMediaQuery("(max-width:992px)");

  return (
    <TextField
      {...props}
      id="outlined-basic"
      variant="outlined"
      helperText={errorText}
      error={!!errorText}
      {...field}
      InputProps={{
        sx: {
          borderRadius: 2,
          fontSize: media ? "0.8rem" : "1rem",
        },
      }}
    >
      {props.select &&
        props.options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </TextField>
  );
};
