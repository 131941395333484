import React from "react";
import {
  Route,
  Routes,
  Navigate,
  BrowserRouter,
  useNavigate,
} from "react-router-dom";

import Header from "./components/header";
import loadable from "@loadable/component";

import Login from "./pages/login";
import Sidebar from "./components/sidebar";
import FindMy from "./services/auth";
import Profile from "./pages/profile";
import CreateUser from "./pages/user/create";
import UpdateUser from "./pages/user/update";
import Document from "./pages/document";
import CircularProgress from "@mui/material/CircularProgress";

const Home = loadable(() => import("./pages/home"));
const User = loadable(() => import("./pages/user"));

const routesList = {
  create_user: {
    title: "Create new user",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "User",
        Link: "/user",
        isActive: false,
      },
      {
        Name: "New user",
        Link: "/user/create",
        Title: "Create new user",
        isActive: true,
      },
    ],
  },
  user: {
    title: "List of users",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "User",
        Link: "/user",
        Title: "List of users",
        isActive: true,
      },
    ],
  },
  documents: {
    title: "List of documents",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "Documents",
        Link: "/documents",
        Title: "List of documents",
        isActive: true,
      },
    ],
  },
  update_user: {
    title: "Update user",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "User",
        Link: "/user",
        isActive: false,
      },
      {
        Name: "Update user",
        Link: "/user/:id",
        Title: "Update user",
        isActive: true,
      },
    ],
  },
  dashboard: {
    title: "Dashboard",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: true,
      },
    ],
  },
  profile: {
    title: "Profile",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "Profile",
        Link: "/profile",
        Title: "Profile",
        isActive: true,
      },
    ],
  },
};

function RequireAuth({ children, isLogged, info, pathName }) {
  const navigator = useNavigate();

  return isLogged ? (
    <>
      <Header info={info} />
      <div style={{ backgroundColor: "rgb(244, 246, 248)" }}>
        <Sidebar info={info} />
        <div className="page-wrapper">
          <div className="route">
            <div className="header-default">{routesList[pathName].title}</div>
            {routesList[pathName].list.map((route, key) => {
              return (
                <span
                  key={key}
                  onClick={() =>
                    route.isActive === false ? navigator(route.Link) : null
                  }
                >
                  <span
                    className={
                      route.isActive ? "header-active" : "header-inactive"
                    }
                  >
                    {route.Name}
                  </span>
                  {key !== routesList[pathName].list.length - 1 && (
                    <span
                      style={{
                        marginInline: 15,
                        color: "rgba(35,43,53, 0.5)",
                      }}
                    >
                      •
                    </span>
                  )}
                </span>
              );
            })}
          </div>
          <div className="content container-fluid">{children}</div>
        </div>
      </div>
    </>
  ) : (
    <Navigate to={"/login"} />
  );
}

const AppRouter = () => {
  const [isLogged, setIsLogged] = React.useState(null);
  const [info, setInfo] = React.useState(null);

  React.useEffect(() => {
    isUserAuthenticated();
  }, []);

  async function isUserAuthenticated() {
    const content = await FindMy();
    if (content.status === "ok") {
      setIsLogged(true);
      setInfo(content.data);
    } else {
      setIsLogged(false);
      setInfo(null);
    }
  }

  if (isLogged === null) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress sx={{ color: "black" }} />
      </div>
    );
  }

  return (
    <div className="main-wrapper">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth
                isLogged={isLogged}
                info={info}
                pathName={"dashboard"}
              >
                <Home info={info} />
              </RequireAuth>
            }
          />
          <Route
            path="/user"
            element={
              <RequireAuth isLogged={isLogged} info={info} pathName={"user"}>
                <User />
              </RequireAuth>
            }
          />

          <Route path="/login" element={<Login />} />

          <Route
            path="/profile"
            element={
              <RequireAuth isLogged={isLogged} info={info} pathName={"profile"}>
                <Profile info={info} />
              </RequireAuth>
            }
          />

          <Route
            path="/user/create"
            element={
              <RequireAuth
                isLogged={isLogged}
                info={info}
                pathName={"create_user"}
              >
                <CreateUser />
              </RequireAuth>
            }
          />

          <Route
            path="/user/:id"
            element={
              <RequireAuth
                isLogged={isLogged}
                info={info}
                pathName={"update_user"}
              >
                <UpdateUser />
              </RequireAuth>
            }
          />

          <Route
            path="/documents"
            element={
              <RequireAuth
                isLogged={isLogged}
                info={info}
                pathName={"documents"}
              >
                <Document />
              </RequireAuth>
            }
          />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AppRouter;
