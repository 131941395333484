import React from "react";
import { FindDocuments, PreviewDocument } from "../../services/document";
import wordIcon from "../../assets/word-logo.png";
import CircularProgress from "@mui/material/CircularProgress";

export default function Document() {
  const [documents, setDocuments] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorDescription, setErrorDescription] = React.useState("");

  const handleError = (description) => {
    setError(true);
    setErrorDescription(description);
  };

  async function getDocuments() {
    try {
      setLoading(true);
      const content = await FindDocuments();

      if (content.error) {
        handleError(content.error);
      } else if (content.status === "OK") {
        setDocuments(content.data);
      } else {
        handleError("Something went wrong, please try again later.");
      }
    } catch {
      handleError("Something went wrong, please try again later.");
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    getDocuments();
  }, []);

  async function previewDoc(docpath) {
    const response = await PreviewDocument(docpath);
    let tempLink;
    tempLink = document.createElement("a");
    tempLink.href = response;
    tempLink.setAttribute("download", docpath);
    tempLink.click();
  }

  if (error) {
    return <div>{errorDescription}</div>;
  }

  return (
    <div className="docs-table">
      {documents.map((val, key) => {
        return (
          <div
            key={key}
            className="docs-card"
            onClick={() => previewDoc("/" + val.path)}
          >
            <div className="docs-card-header">
              <span className="card-image-container">
                <span className="card-image-wrapper"></span>
                <img
                  alt=""
                  src="https://api-prod-minimal-v510.vercel.app/assets/images/cover/cover_1.jpg"
                  className="card-image"
                ></img>
              </span>
              <div className="word-logo-backdrop"></div>
              <div className="word-logo-container">
                <img alt="s" src={wordIcon} className="word-logo" />
              </div>
            </div>
            <div className="docs-card-title">{val.name}</div>
            <div className="docs-card-subtitle">Template</div>
            <div className="docs-card-footer">Download</div>
          </div>
        );
      })}
    </div>
  );
}
